import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class ReadingList extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Reading List" />
       <div style={{maxWidth: '1200px', padding: '1.992rem', margin: '0 auto'}}>
        <h1>Reading List</h1>
        <p>
          Books I’ve read, when I finished them, and occasionally links to reviews. 
        </p>
        <p>
          ** Indicates a book that I give <span role="img" aria-label='five stars'>⭐⭐⭐⭐⭐</span> and highly recommend.
        </p>
        <h2>2020</h2>
        <ol>
          <li>**Just Enough Research - </li>
          <li>Practical Empathy - </li>
        </ol>
       </div>
      </Layout>
    )
  }
}

export default ReadingList

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
